import React, { useContext } from "react";
import forgotPasswordStyles from "./forgotPasswordStyles.module.css";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Forgotschema from "./Forgotschema";
import { FormInput, Button } from "../../../components";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext/AuthContext";

const fs = forgotPasswordStyles;

type Inputs = z.infer<typeof Forgotschema>;
function ForgotPassword() {
  const { forgotPassword } = useContext(AuthContext)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Inputs>({
    resolver: zodResolver(Forgotschema),
  });

  const onSubmit = async (data: Inputs) => {
    console.log(data);
  };

  const navigate = useNavigate();
  const navigateTosetnewpassword = () => {
    navigate('/setnewpassword')
  }

  const onSubmitWithRedirect = async (data: Inputs) => {
    await onSubmit(data);
    const res = await forgotPassword.requestCode(data?.email)
    if (res?.statusCode === 200) {
      alert("Password set Successfully")
      navigateTosetnewpassword();
    }

  };

  return (
    <div className={fs.main}>
      <div className={fs.cadconsdroid}>
        <h1 className={fs.maintitle}> CAD CONSDROID</h1>
      </div>
      <div className={fs.form}>
        <div className={fs.content}>
          <div className={fs.title}>
            <h3> Forgot Password</h3>
          </div>
          <FormInput
            type="email"
            className={fs.inputbox}
            placeholder="Email"
            {...register("email")}
          />
          {errors.email && (
            <p className={fs.errors} id="email-error">
              {errors.email?.message}
            </p>
          )}
        </div>
        <div className={fs.button_style}>

          <Button
            className={fs.forgotpassword_button}
            onClick={handleSubmit(onSubmitWithRedirect)}
          >
            send OTP
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
