import React, { useContext, useEffect } from "react";
import SupportDetailstyles from "./SupportDetailsStyles.module.css";

const scs = SupportDetailstyles;

// interface ISupportDetails {
//   chatData: never[];
// }
function SupportDataCard({ chatData }: any) {
  return (
    <div className={scs.accounts_card_main}>
      <div className={scs.accounts_card}>
        <div className={scs.card_title}>
          <b>User Details</b>
        </div>
        <div className={scs.card_content_div}>
          <div className={scs.card_content}>
            <div className={scs.content_title}>Support ID</div>
            <div className={scs.content}>
              {chatData?.cad_support_details?.id}
            </div>
          </div>
          <div className={scs.card_content}>
            <div className={scs.content_title}>Name</div>
            <div className={scs.content}>
              {" "}
              {chatData?.cad_support_details?.created_by_username}
            </div>
          </div>
          <div className={scs.card_content}>
            <div className={scs.content_title}>Email</div>
            <div className={scs.content}>
              {chatData?.cad_support_details?.email}
            </div>
          </div>
          <div className={scs.card_content}>
            <div className={scs.content_title}>Mobile</div>
            <div className={scs.content}>
              {chatData?.cad_support_details?.mobile}
            </div>
          </div>
          <div className={scs.card_content}>
            <div className={scs.content_title}>Reason</div>
            <div className={scs.content}>
              {chatData?.cad_support_details?.reason_for_support}
            </div>
          </div>
          <div className={scs.card_content}>
            <div className={scs.content_title}>comments</div>
            <div className={scs.content}>
              {chatData?.cad_support_details?.comments}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportDataCard;
