import React from "react";
import AcceptedProjectStyles from "./acceptedprojectStyles.module.css";
import builldingLine from "../../../../assets/building_line.svg";
import { useNavigate } from "react-router-dom";
const aps = AcceptedProjectStyles;

const Acceptedprojects = (props: any) => {
  const navigate = useNavigate();

  //From Parent => (OngoingJobs) is passed as props here it contains the value of job_id and project_key and the handlefunction navigates to <Job />

  const handlefunction = (job_id: any, project_folder_key: any) => {
    console.log(job_id, project_folder_key);
    navigate(`${job_id}`, {
      state: { id: job_id, key: project_folder_key },
    });
  };
  return (
    <>
      <div className={aps.con}>
        <div>
          <table className={aps.table}>
            <tr>
              <th>S.no</th>
              <th>Project Folder</th>
            </tr>
            {props.OngoingJobs?.map((ongoing: any, id = 0) => (
              <tr key={id}>
                <td>{++id}</td>
                <td
                  onClick={() => {
                    handlefunction(
                      ongoing?.job_id,
                      ongoing?.project_folder_key
                    );
                  }}
                >
                  {ongoing.job_id}
                </td>
              </tr>
            ))}
            {console.log(props.OngoingJobs)}
          </table>
        </div>
        <div>
          <img className={aps.image} src={builldingLine} alt="" />
        </div>
      </div>
    </>
  );
};

export default Acceptedprojects;
