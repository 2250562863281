import React, { useState } from "react";
import previewStyles from "./previewStyles.module.css";
import { Button, FormInput } from "../../../../components";
import LetterHead from "../../../../assets/LetterHead1.jpg";
import { useLocation, useNavigate } from "react-router-dom";

const ps = previewStyles;

function Preview() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state);
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleChange = () => {
    setChecked(!checked);
    setDisabled(!disabled);
  };
  //  const handleApply=(()=>{
  //   location.state.handleSubmit();
  //  })
  return (
    <div className={ps.previewpage}>
      <div className={ps.image}>
        <img
          src={LetterHead}
          alt="cadcosndroid-letter head"
          className={ps.img}
        ></img>
      </div>
      <div className={ps.content}>
        <h3 className={ps.title}>NDA Agreement</h3>

        <div className={ps.cadcontent}>
          Employer :
          <span className={ps.cadinputs}>
            <b> Francis </b>
          </span>
          with a mailing address of
          <span className={ps.cadinputs}>
            <b> info@consdroid.com</b>
          </span>
        </div>

        <div className={ps.cadcontent}>
          Employee :
          <span className={ps.cadinputs}>
            <b> {location.state.values?.firstName} </b>
          </span>
          with a mailing address of
          <span className={ps.cadinputs}>
            <b> {location.state.values?.email}</b>
          </span>
        </div>

        <div className={ps.cadcontent}>
          WHEREAS the Employer intends to hire the Employee for the Position and
          the Employee desires to provide their services to the Employer for
          payment. <br></br>
        </div>
        {/* <div className={ps.cadcontent}>
          provide their services to the Employer for payment.
        </div> */}
        <div className={ps.cadcontent}>
          IN CONSIDERATION of promises and other good and valuable
          considerations, the parties agree to the following:
        </div>
        <div className={ps.cad_timing}>
          I. <span className={ps.text}>EMPLOYEE DUTIES : </span>The Employee
          agrees that they will act by this Agreement and with the best
          interests of the Employer in mind, which may or may not require them
          to present the best of their skills, experience, and talents, to
          perform all the duties required of the position. In carrying out the
          duties and responsibilities of their position, the Employee agrees to
          adhere to all policies, procedures, rules, and regulations, as
          administered by the Employer. In addition, the Employee agrees to
          abide by all local, country, State, and Federal laws while employed by
          the Employer. <br></br>
        </div>
        <div className={ps.cad_timing}>
          II. <span className={ps.text}>RESPONSIBILITIES : </span> The Employee
          shall be given the job title of
          <span className={ps.cadinputs}>CAD DESIGNER</span>
        </div>
        <div className={ps.cadtiming_check}>
          The Employer may also assign duties to the Employee from time to time
          by the Employer. The Employee shall be expected to work on
          <span className={ps.text}> part-time</span>
        </div>
        {/* <div className={ps.cadcontent}>  ☐ part-time.</div> */}

        <div className={ps.cad_timing}>
          III. <span className={ps.text}>EMPLOYMENT PERIOD : </span> The
          Employer agrees to hire the Employee , At-Will which means this
          Agreement may be terminated at any time by either the Employee or
          Employer. After termination by any of the Parties, neither will have
          any obligation other than the non-disclosure of the Employer’s
          proprietary information as outlined in Section VII.
        </div>
        <div className={ps.cad_termination}>
          <div className={ps.cad_timing}>
            a) <span className={ps.text}>Employee’s Termination : </span>The
            Employee shall have the right to terminate this Agreement by
            providing at least 15 days’ notice.
          </div>
          <div className={ps.cad_timing}>
            b) <span className={ps.text}>Employer’s Termination : </span>The
            Employer shall have the right to terminate this Agreement by
            providing at least 15 days’ notice.
          </div>
        </div>
        <div className={ps.cad_timing}>
          IV. <span className={ps.text}>PAY : </span>
          As compensation for the services provided, the Employee shall be paid
          based on the number of projects worked. The Compensation is a gross
          amount that is subject to all local, State, Federal, and any other
          taxes and deductions as prescribed by law. Payment shall be
          distributed to the Employee on a
          <span className={ps.text}> monthly basis</span> depending upon the
          projects worked
        </div>

        <div className={ps.cad_timing}>
          V. <span className={ps.text}>CONFIDENTIALITY : </span>
          The Employee understands and agrees to keep any information
          confidential regarding the business plans, inventions, designs,
          products, services, processes, trade secrets, copyrights, trademarks,
          customer information, customer lists, prices, analytics data, costs,
          affairs, and any other information that could be considered
          proprietary to the Employer ("Confidential Information"). The Employee
          understands that disclosure any such Confidential Information,
          directly or indirectly, shall result in litigation with the Employer
          eligible for equitable relief to the furthest extent of the law,
          including but not limited to filing claims for losses and/or damages.
          In addition, if it is found that the Employee divulged Confidential
          Information to a third (3rd) party the Employer shall be entitled to
          all reimbursement for their legal and attorney’s fees.
        </div>

        <div className={ps.cad_timing}>
          VI. <span className={ps.text}>RETURN OF PROPERTY : </span> The
          Employee agrees to return any property of the Employer upon the
          termination of employment. This includes, but is not limited to,
          equipment, electronics, records, access, notes, data, tests, vehicles,
          reports, models, or any property that is requested by the Employer.
        </div>
        <div className={ps.cad_timing}>
          VII. <span className={ps.text}>ENTIRE AGREEMENT : </span>
          This Agreement, along with any attachments or addendums, represents
          the entire agreement between the parties. Therefore, this Agreement
          supersedes any prior agreements, promises, conditions, or
          understandings between the Employer and Employee
        </div>
      </div>

      <div className={ps.bottom_content}>
        <div className={ps.check}>
          <FormInput
            type="checkbox"
            width="100"
            checked={checked}
            onChange={handleChange}
          />
          <span className={ps.checktext}>Agree to the contract </span>
        </div>
        <div className={ps.buttons}>
          <Button
            color="color2"
            disabled={disabled}
            onClick={() => {
              alert("Successfully Submitted");
              navigate(`/`);
            }}
          >
            Register
          </Button>
          <Button
            className={ps.print_btn1}
            onClick={() => {
              window.print();
            }}
          >
            Print
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Preview;
