import { Email, Call } from "../../assets";
import { z } from "zod";

const schema = z.object({
  reason_for_support: z.string().min(5, "Enter the reason").nonempty(),
  comments: z.string().min(8, "Please Enter Atleast 8 Valid Inputs").nonempty(),
});
const SUPPORT_DATA = [
  { icon: Email, Details: "info@consdroid.com", alt: "Email Image" },
  { icon: Call, Details: "+91 91500 80335", alt: "CallImage" },
];
const Data_Validation = { SUPPORT_DATA, schema };
export default Data_Validation;
