import React, { useContext } from 'react'
import setnewPasswordStyles from "./setnewpasswordStyles.module.css";
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
// import setschema from "../../../Auth/SetNewPassword/Setschema"
// import Setschema from '../../../Auth/SetNewPassword/Setschema';
import { AuthContext } from '../../../../context/AuthContext/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FormInput } from "../../../../components";

const Setschema = z
  .object({
    password: z
      .string()
      .min(8, "Password must contain at least 8 character(s)")
      .nonempty(),
  })
  .required();

const suss = setnewPasswordStyles;
type Inputs = z.infer<typeof Setschema>;

function SetPassword() {
  const navigate = useNavigate();
  const { newPasswordChallenge } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(Setschema),
  });

  const onSubmit = async (data: Inputs) => {
    console.log(data, "is here");
    const res = await newPasswordChallenge(data?.password);
    if (res?.statusCode === 200) {
      alert("Password Changed Successfully");
      navigate(`/`);
    }
  };

  return (
    <div className={suss.container}>
      <div className={suss.main}>
        <div className={suss.cadconsdroid}>
          <h1> CAD CONSDROID</h1>
        </div>
        <div className={suss.form}>
          <h1> Set Password</h1>
          <div className={suss.inputs}>
            <FormInput
              type="password"
              className={suss.setpasswordinput}
              placeholder="Password"
              {...register("password")}
            />
            {errors.password && (
              <p className={suss.errors} id="password-error">
                {errors.password?.message}
              </p>
            )}
          </div>
          <button className={suss.button} onClick={handleSubmit(onSubmit)}>
            Set Password
          </button>
        </div>
      </div>
    </div>
  );
}

export default SetPassword